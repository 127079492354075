/* Note: use only to override very specific theme styles. 
All other css rules should be written in their own respective sass documents. */
.rt-tbody {
  overflow: visible !important;
}
.sticky {
  z-index: 9996;
  position: sticky !important;
  left: 0;
  top: 0;
}

.left-175 {
  left: 175px;
}

.left-350 {
  left: 350px;
}

.left-525 {
  left: 525px;
}